body {
  font-family: 'Figtree', sans-serif;
}

.form-control.is-invalid {
  background: #d1d1d1;
}

.login-footer {
  margin-bottom: 10px;
  bottom: 0px;
  text-align: center;
  width: 99%;
  font-size: 0.9em;
  color: #868686;
  margin-left: -12px;
}

.login-footer a {
  color: #0185ff;
}

.action-button {
  background-color: #2879bf !important;
}

.action-button:hover {
  background-color: #1a5283 !important;
}

.login-container {
  font-family: 'Figtree', sans-serif;
  min-height: 100vh;
  overflow-x: hidden;
  width: 100%;
}

.split-layout {
  min-height: 100vh;
  margin: 0 !important;
  width: 100%;
  overflow-x: hidden;
}

.left-side {
  background-color: #1a1a1a;
  color: white;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-content {
  max-width: 500px;
  padding: 2rem;
}

.left-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.left-content p {
  font-size: 1.2rem;
  opacity: 0.8;
}

.right-side {
  background-color: #f8f9fa;
  background-image: url('https://cdn.prod.website-files.com/676efe8643015d2cbd96effe/676efe8643015d2cbd96f0d3_SectionBG_Form.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form-container {
  padding: 2rem;
  max-width: 450px;
  margin: 0 auto;
  width: 100%;
}

.login-form-wrapper {
  background: rgba(255, 255, 255, 0.95);
  padding: 2.5rem;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

.login-title {
  font-size: 1.8rem !important;
  font-weight: 700 !important;
  margin-bottom: 2rem !important;
  text-align: center;
  color: #212121;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #212121;
  font-weight: 500;
}

.login-button {
  width: 100%;
  padding: 0.8rem !important;
  margin-top: 1rem;
  background-color: #007bff;
  border: none;
}

.login-button:hover {
  background-color: #0056b3;
}

.forgot-password {
  margin-top: 1.5rem;
  text-align: center;
}

.forgot-password a {
  color: #6c757d;
  text-decoration: none;
}

.forgot-password a:hover {
  color: #007bff;
}

.error-alert {
  margin-bottom: 1rem;
  border-radius: 4px;
}

.login-footer {
  text-align: center;
  margin-top: 2rem;
  color: #6c757d;
  font-size: 0.9rem;
  width: 100%;
  padding: 0 1rem;
}

@media (max-width: 768px) {
  .left-side {
    display: none; /* Hide left side on mobile */
  }

  .right-side {
    width: 100%;
  }

  .login-form-container {
    padding: 1rem;
  }
}

.success-message {
  text-align: center;
  margin-bottom: 2rem;
  color: #28a745;
}

.success-message p {
  margin-bottom: 0.5rem;
}

.email-display {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #212121;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
}

.secondary-button {
  width: 100%;
  padding: 0.8rem !important;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #212121;
}

.secondary-button:hover {
  background-color: #e9ecef;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.error-alert {
  margin-bottom: 1.5rem;
  background-color: #fff;
  border: 1px solid #dc3545;
  color: #dc3545;
}
